$foreground-color: #343a40;
$secondary-foreground-color: #ffffff;

$apexiem-teal: #3bbfb0;
$apexiem-navy: #003263;
$apexiem-light-blue: #337ab7;

$primary-background-color: #013363;
$primary-background-color-alt: #03284c;
$secondary-background-color: #f5f6f9;
$secondary-background-color-alt: #d1d9f3;

$primary-action-color: $apexiem-light-blue;
//$primary-action-color-alt: #2e968a;
$primary-action-color-alt: $apexiem-navy;
$secondary-action-color: #6c757d;
$secondary-action-color-alt: #5a6269;
$danger-action-color: #dc3545;
$danger-action-color-alt: #b12b39;
$success-action-color: #28a745;
$success-action-color-alt: #1f8a38;
$info-action-color: #007bff;
$info-action-color-alt: #0266d1;

$primary-action-text-color: #ffffff;
$secondary-action-text-color: #d3d3d3;
$primary-link-color: #117ac9;
