.table-container {
    min-width: 1200px;
}

.table-header {
    background-color: #374355;
    color: $secondary-foreground-color;
}

.table-row {
    font-size: 16px;

    &:hover {
        background-color: $secondary-background-color-alt !important;
    }
}

.container-striped {
    span:nth-child(odd) div:last-child {
        background-color: #f2f2f2;
    }

    span:nth-child(even) div:last-child {
        background-color: $secondary-foreground-color;
    }
}

.container-striped-alt {
    .table-row:nth-child(odd) {
        background-color: #f2f2f2;
    }

    .table-row:nth-child(even) {
        background-color: $secondary-foreground-color;
    }
}
