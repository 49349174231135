@import "./_variables.scss";

/* 
button types:
default
cancel
persist (save/submit/etc)
delete

also:
can be disabled

*/

.action-button {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding-top: .7em;
    min-width: 150px;
    height: 3em;
    border-radius: .2em;
    color: $primary-action-text-color;
    background-color: white;
    padding-left: .5em;
    padding-right: .5em;

    border-color: black;
    border-style: solid;
    border-width: 1px;

    &_disabled {
        opacity: 0.5;
        cursor: default;
    }

    &_default {
        border-color: $primary-action-color;
        color: $primary-action-color;
        &:not(.action-button_disabled):hover {
            background-color: $primary-action-color-alt;
            border-color: $primary-action-color-alt;
            color: white;
        }
    }

    &_cancel {
        background-color: $secondary-action-color;
        &:not(.action-button_disabled):hover {
            background-color: $secondary-action-color-alt;
            border-color: $secondary-action-color-alt;
        }
    }

    &_delete {
        background-color: $danger-action-color;
        border-color: $danger-action-color;
        &:not(.action-button_disabled):hover {
            background-color: $danger-action-color-alt;
            border-color: $danger-action-color-alt;
        }
    }

    &_save {
        background-color: $success-action-color;
        border-color: $success-action-color;
        &:not(.action-button_disabled):hover {
            background-color: $success-action-color-alt;
            border-color: $success-action-color-alt;
        }
    }

    &_next {
        background-color: $primary-action-color;
        border-color: $primary-action-color;
        color: white;
        &:not(.action-button_disabled):hover {
            background-color: $primary-action-color-alt;
            border-color: $primary-action-color-alt;
            color: white;
          }
      }

    &_small {
        margin-top: .2em;
        margin-bottom: .2em;
        padding-top: 0em;
        min-width: 90px;
        height: 1.5em;
    }

    &_medium {
        padding-top: 0;
        height: 2.5em;
        font-weight: bold;
        background-color: $primary-action-color;
        width: 100%;
    }
}
