@import "./_buttons.scss";
@import "./_variables.scss";
@import "./_tables.scss";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

::-webkit-input-placeholder {
    font-style: italic;
 }
 :-moz-placeholder {
    font-style: italic;  
 }
 ::-moz-placeholder {
    font-style: italic;  
 }
 :-ms-input-placeholder {  
    font-style: italic; 
 }

body {
    color: $foreground-color;
}

a {
    color: $primary-link-color;
}

.withBackground {
    background-image: url('/assets/background2.png');
}

.rounded-ninety {
    border-radius: .9em;
}

.relative {
    position: relative;
}

.content {
    min-height: 100vh;
    padding-bottom: 60px
}

.footer {
    height: 60px;
    margin-top: -60px;
}

.edit-height {
    min-height: 3em;
    padding-top: .2em;
    padding-bottom: .1em;
}

.edit-height-padding {
    padding-top: .2em;
    padding-bottom: .1em;
}

.unedit-padding {
    padding-top: .3em;
}

.light-blue-bordered {
    border-color: $apexiem-light-blue;
}

.bordered-container {
    border-style: solid;
    border-width: 2px;
    border-radius: .9em;
}

.bordered-container-tabbed {
    border-style: solid;
    border-width: 2px;
    border-radius: .0em .9em .9em .9em;
}

.bordered-container-above {
    border-style: solid;
    border-width: 2px 2px 0px 2px;
    border-radius: .9em .9em 0em 0em;
}

.bordered-container-below {
    border-style: solid;
    border-width: 0px 2px 2px 2px;
    border-radius: 0em 0em .9em .9em;
}

.bordered-container-middle {
    border-style: solid;
    border-width: 0px 2px;
}

.input-style {
    height: 2.2em;
    width: 95%;
    // border-radius: .3em;
    padding-left: .5em;
    margin-left: .5em;
}

.input-style-in-list {
    border-radius: .3em;
    padding-left: .1em;
}

.login-input-style {
    height: 2.2em;
    width: 15em;
    border-radius: .3em;
    padding-left: .5em;
    border-width: 1px;
    border-color: lightgrey;
    border-style: solid;
}

.link {
    cursor: pointer;
    color: $primary-link-color;

    &:hover {
        text-decoration: underline !important;
    }
}

.link-color {
    color: $primary-link-color;
}

.checkbox-style {
    height: 20px;
    width: 20px;
    vertical-align: middle;
}

input[type=checkbox] {
    height: 1.5em;
    width: 1.5em;
}

.data-border {
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-style: solid;
}

.search-result {
    background-color: white;
    color: black;
    cursor: pointer;

    &:hover {
        background-color: blue;
        color: white;
    }
}

.dropdown-container {
    width: 88%;
    display: none;
    position: absolute;
    top: 2em;
    left: 15px;
    z-index: 3;
    max-height: 10em;
    overflow: hidden scroll;
    border-style: solid;
    border-color: black;
    border-width: 0px;
}

.dropdown-arrow {
    position: absolute;
    top: .25em;
    /*left: 70%;*/
    right: 24px;
    z-index: 2;
    color: black;
}

.bold {
    font-weight: bold;
}

.attribute-h1 {
    font-size: 1.5em;
}

.attribute-h2 {
    font-size: 1.3em;
}

.attribute-h3 {
    font-size: 1.15em;
}

.hidden {
    display: none !important;
}

.popuptext {
    font-weight: normal;
    display: none;
    position: absolute;
    z-index: 3;
    left: 27px;
    top: -10px;
    width: 10em;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-radius: .4em;
}

/* Popup arrow */
.popuptextarrow {
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    z-index: 3;
    content: "";
    position: absolute;
    /*top: 30%;*/
    left: -5px;;
    margin-left: -5px;
    /*border-width: 1px;*/
    border-left-width: 1px;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 0px;

    border-style: solid;

    position: absolute;
    left: 27px;
    top: 6px;
}

.popuptextleft {
    font-weight: normal;
    display: none;
    position: absolute;
    z-index: 3;
    right: 27px;
    top: -10px;
    width: 10em;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-radius: .4em;
}

/* Popup arrow */
.popuptextarrowleft {
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    z-index: 3;
    content: "";
    position: absolute;
    /*top: 30%;*/
    right: -5px;;
    margin-right: -5px;
    /*border-width: 1px;*/
    border-left-width: 0px;
    border-top-width: 0px;
    border-right-width: 1px;
    border-bottom-width: 1px;

    border-style: solid;

    position: absolute;
    right: 27px;
    top: 6px;
}

.even-row-color {
    background-color: #DDDDDD;
}

.horizontal-scroll {
    overflow-x: auto;

    .row {
        flex-wrap: nowrap;
    }
}

.tab {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

    &:hover {
        text-decoration: underline !important;
    }

    border-style: solid;
    border-width: 2px;
    border-bottom-width: 0px;
    border-radius: .6em .6em 0em 0em;
}

.current-tab {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-style: solid;
    border-width: 2px;
    border-bottom-width: 0px;
    border-radius: .6em .6em 0em 0em;
    font-weight: bold;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.confirm-box {
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: .9em;
}

.table-break {
    width: 90%;
    border-style: solid;
    border-color: darkgrey;
    border-top-width: 1px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.table-bottom {
    border-bottom-width: 1px;
}

.search-panel {
    border-radius: .2em;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: lightgrey;
}

.search-header-row {
    background-color: white;
    border-style: solid;
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-color: lightgrey;
}

.link-rebrand {
    cursor: pointer;
    font-weight: bold;
    color: $primary-link-color;
}

.link-rebrand:hover {
    color: #3bbfb0;
    text-decoration: underline;
}

.footer-link {
    cursor: pointer;
    font-weight: bold;
    color: #3bbfb0;
}

.footer-link:hover {
    color: $primary-link-color;
    text-decoration: underline;
}

.saq-tooltip {
    color: #3bbfb0;
}

.no-break {
    white-space: nowrap;
}

.bottom-lightgrey-border {
    border-style: solid;
    border-color: lightgrey;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
}

body {
    margin: 0;
    font-family: 'Helvetica', 'Arial', sans-serif;
}

@import "../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

.custom-dialog-container .mat-dialog-container {
    padding: 0;
    width: 450px;
    //height: 400px;
    height: 24em;
}

.custom-dialog-container-no-reason .mat-dialog-container {
    padding: 0;
    width: 450px;
    //height: 400px;
    height: 16em;
}

.delete-confirmation-dialog-container .mat-dialog-container {
    padding: 0;
    width: 450px;
    //height: 400px;
    height: 12em;
}

.thick-menu-border {
    border-style: solid;
    border-width: 3px;
    border-color: #f7f8f9;
    background-color: #f7f8f9;
}

.selectedMenu {
    border-bottom-color: #0574c6;
    //color: white;
}

.thick-border {
    border-style: solid;
    border-width: 3px;
    border-color: #003263;
}

.selected-submenu {
    border-bottom-color: #3bbfb0;
    color: $secondary-foreground-color;

    &:hover {
        color: $secondary-foreground-color;
        background-color: $primary-background-color-alt;
        border-color: $primary-background-color-alt;
        border-bottom-color: $primary-action-color;
    }
}

.unselected-submenu {
    color: $secondary-action-text-color;

    &:hover {
        color: $secondary-foreground-color;
        background-color: $primary-background-color-alt;
        border-color: $primary-background-color-alt;
    }
}

.pointer {
    cursor: pointer;
}

.grey-list-title-row {
    border-style: solid;
    border-right-width: 0px;
    border-left-width: 0px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: lightgrey;
    background-color: #f5f6f9;
}

.invoice-secondary-submenu {
    display: flex;
    align-items: center;
    //justify-content: start;
    background-color: $secondary-background-color;

    &_selectedItem {
        border-bottom-style: solid;
        border-bottom-width: 3px;
        border-bottom-color: $primary-action-color;
        color: $primary-background-color;
    }

    &_unselectedItem {
        color: $foreground-color;
    }

    &_item {
        display: flex;
        align-items: center;
        height: 4em;
    }
}

.dark-row {
    background-color: #f3f3f3;
}

.darker-row {
    background-color: #dfdfdf;
}

.dashboard-header {
    border-top: 0px;
    border-style: solid;
    border-color: lightgrey;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
}

.overflow {
    overflow: visible;
}

.paperclip-documents {
    color: #117ac9
}

.paperclip-sbl {
    color: #3bbfb0;
}

.paperclip-final {
    /*color: #3bbf3b;*/
    color: #DDA0DD
}

.apxm-text {
    color: $foreground-color;
}

.submenu-container {
    display: block;
    min-width: 90%;
    background-color: $primary-background-color;
    min-height: 4em;
    margin-left: -30px;
    margin-right: -45px;
}

.submenu-item {
    display: inline-block;
}

.status-badge {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: .25em; 
    color: white;
    font-weight: bold; 
    vertical-align: middle; 
    white-space: nowrap;
}

.order-status-C {
    background-color: #c9d3df;
}

.order-status-E {
    background-color: #ea676d;
}

.order-status-I {
    background-color: #3bbfb0;
}

.order-status-M {
    background-color: #fdb858;
}

.order-status-N {
    background-color: #117ac9;
}

.order-status-O {
    background-color: #fdb858;
}

.order-status-Q {
    background-color: #ea676d;
}

.order-status-R {
    background-color: #0071c5;
}

.order-status-S {
    background-color: #ea676d;
}

.workflow-status-D {
    background-color: #0071c5;
}

.workflow-status-I {
    background-color: #3bbfb0;
}

.workflow-status-M {
    background-color: #fdb858;
}

.workflow-status-T {
    background-color: #ea676d;
}

.workflow-status-W {
    background-color: #fd6a02;
}

.workflow-status-P {
    background-color: grey;
}

.workflow-status-Y {
    background-color: green;
}

.invoice-status-D {
    background-color: #fdb858;
}

.invoice-status-L {
    background-color: #ea676d;
}

.invoice-status-P {
    background-color: #3bbfb0;
}

.invoice-status-S {
    background-color: #c9d3df;
}

.invoice-status-W {
    background-color: darkgrey;
}

.validation-error {
    background-color: pink;
    border-radius: .5em;
    padding-left: .75em;
    padding-right: .75em;
    width: 100%;
    display: inline-block;
}

.validation-error-banner {
    background-color: #FFFBE5;
    color: red;
    border-style: solid;
    border-width: 1px;
    border-color: red;
}

.nowrap {
    white-space: nowrap;
}

.float-right {
    float: right;
}